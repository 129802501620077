<template>
  <div class="dwrap">
    <div class="detection" v-if="!showConfig">
      <div class="detection-logo">
        <!-- <img src="@/assets/img/detection/logo.png" /> -->
        <img :src="$isZh() ? $img('detection/logo.png') : $img('detection/logo-en.png')" alt="" />
      </div>
      <div class="detection-content">
        <div class="detectionTab-content">
          <el-tabs v-model="activeName">
            <el-tab-pane class="detection-content__item" label="文件提交" name="file">
              <template #label>
                <span>
                  <img class="detection-content__tabImg" :src="$img('detection/file.svg')" alt="" />
                  <img :src="$img('detection/file-active.svg')" class="detection-content__tabImg--active" alt="" />
                  <span>{{$t('detection.tab.file')}}</span>
                </span>
              </template>
              <sand-file-upload ref="fileUpload" @submit="toConfig"></sand-file-upload>
            </el-tab-pane>
            <el-tab-pane class="detection-content__item" label="URL分析" name="url">
              <template #label>
                <span>
                  <img class="detection-content__tabImg" :src="$img('detection/url.svg')" alt="" />
                  <img class="detection-content__tabImg--active" :src="$img('detection/url-active.svg')" alt="" />
                  <span>{{$t('detection.tab.url')}}</span>
                </span>
              </template>
              <sand-url-upload ref="urlUpload" @submit="toConfig"></sand-url-upload>
            </el-tab-pane>
            <el-tab-pane
              v-if="userInfo.group === 'qihoo'"
              class="detection-content__item"
              label="哈希提交"
              name="hash"
            >
              <template #label>
                <span>
                  <img class="detection-content__tabImg" :src="$img('detection/hash.svg')" alt="" />
                  <img class="detection-content__tabImg--active" :src="$img('detection/hash-active.svg')" alt="" />
                  <span>{{$t('detection.tab.hash')}}</span>
                </span>
              </template>
              <sand-url-upload ref="hashUpload" :type="'hash'" @submit="toConfig"></sand-url-upload>
            </el-tab-pane>
          </el-tabs>
        </div>
        <span class="tips"><svg-icon name="info"></svg-icon>{{$t('detection.reference')}}</span>
      </div>
    </div>
    <uploadConfig v-else :dataList="dataList" :type="type"></uploadConfig>
    <backTo-top></backTo-top>
  </div>
</template>
<script setup>
// import { mapState } from 'vuex'
// import {onMounted, ref} from 'vue'
import { ref, reactive, onMounted, defineExpose } from 'vue'
import { useState } from '@/hooks/useVuex'
import { useStore } from 'vuex'
import sandFileUpload from './components/sandFileUpload'
import sandUrlUpload from './components/sandUrlUpload'
import uploadConfig from './components/uploadConfig'

const activeName = ref('file')
const showConfig = ref(false)
const store = useStore()

const { userInfo } = useState('user', ['userInfo'])
// const tabList = computed(() => userInfo.value.group === 'group' ? ['windows', 'android', 'linux'] : ['windows', 'android'])
const type = ref('')

const fileUpload = ref(null)
const urlUpload = ref(null)
const hashUpload = ref(null)

// const state = reactive({
//   dataList: []
// })
const dataList = reactive([])
function toConfig(data, fileType) {
  dataList.push(...data)
  type.value = fileType
  showConfig.value = true
}
onMounted(() => {
  const transferFile = window.transferFile
  if (!transferFile) return
  if (transferFile.type === 'file') {
    Array.from(transferFile.data).forEach((item) => {
      fileUpload.value.handleChange(item, transferFile.data.length)
    })
  } else {
    const comp = transferFile.type === 'url' ? urlUpload.value : hashUpload.value
    comp.textarea = transferFile.data
    comp.toConfig()
  }
})

defineExpose({ store })
// export default {
//   components: {
//     sandFileUpload,
//     sandUrlUpload,
//     uploadConfig,
//     detectionTab
//   },
//   data() {
//     return {
//       activeName: 'file',
//       showConfig: false,
//       dataList: [],
//       type: ''
//     }
//   },
//   mounted() {
//     const transferFile = window.transferFile
//     if (!transferFile) return
//     if (transferFile.type === 'file') {
//       transferFile.data.forEach((item) => {
//         this.$refs.fileUpload.handleChange(item, transferFile.data.length)
//       })
//     } else {
//       const comp = transferFile.type === 'url' ? 'urlUpload' : 'hashUpload'
//       this.$refs[comp].textarea = transferFile.data
//       this.$refs[comp].toConfig()
//     }
//   },
//   methods: {
//     toConfig(data, type) {
//       this.dataList = data
//       this.type = type
//       this.showConfig = true
//     }
//   },
//   computed: {
//     ...mapState(['userinfo']),
//     tabList() {
//       if (this.userinfo.group === 'qihoo') {
//         return ['windows', 'android', 'linux']
//       } else {
//         return ['windows', 'android']
//       }
//     }
//   }
// }
</script>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'report')
      // location.reload()
      vm.store.dispatch('user/getScoreInfo')
    })
  }
}
</script>
<style lang="scss" scoped>
[data-theme=light]{
  .dwrap {
    background-image: url('../../../public/assets/img/light/detection/global_background.png');
  }
  .detection {
    .detectionTab-content {
      box-shadow: 0 6px 28px 8px #6f97bf0d, 0 4px 16px 0 #6f97bf14, 0 1px 6px -4px #6f97bf1f;
      border: none;
    }
  }
}
.dwrap {
  width: 100%;
  min-width: 1330px;
  min-height: calc(100vh - 116px);
  // overflow: auto;
  background-image: url('../../../public/assets/img/dark/detection/global_background.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}
.detection {
  font-size: 14px;
  padding-top: calc(50vh - 390px);
  &-logo  {
    margin: 68px auto;
    text-align: center;
    img {
      height: 75px;
      width: 302px;
    }
  }
  &-tooltip {
    position: relative;

    transform: translate(265px, 25px);
    text-align: center;
  }
  &-content {
    width: 630px;
    margin: 0 auto 80px auto;

    overflow: hidden;
    color: var(--color-text-3);
    .detectionTab-content {
      min-height: 310px;
      box-sizing: border-box;
      border-radius: 4px;
      background: var(--detect-content-bg);
      // border: 1px solid #262938;
      box-shadow: 0 4px 35px 0 var(--color-bg-2);
    }
    .tips {
      display: flex;
      font-size: 12px;
      color: var(--color-text-3);
      padding-top: 12px;
      opacity: .6;
      svg {
        font-size: 14px;
        margin-right: 2px;
      }
    }
    & :deep(.el-tabs__header) {
      margin-bottom: 0;
      & .el-tabs__nav  {
        width: 100%;
        display: flex;
      }
      & .el-tabs__active-bar {
        height: 3px;
        padding: 0;
        margin-left: 0;
        border-radius: 1.5px;
        background-color: var(--color-priamry);
        transition: none;
      }
      & .el-tabs__nav-wrap::after  {
        height: 3px;
        background-color: var(--color-border-2);
        opacity: 0.35;
      }
      & .el-tabs__item  {
        padding: 0;
        flex: 1;
        height: 48px;
        line-height: 48px;
        text-align: center;
        transition: none;
        color: var(--color-text-3);
        user-select: none !important;
        outline: none !important;
        img  {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 4px;
          transform: translateY(2px);
        }
        & .detection-content__tabImg--active  {
          display: none;
        }
        &:hover  {
          color: var(--color-text-1);
          & .detection-content__tabImg--active  {
            display: inline-block;
          }
          & .detection-content__tabImg  {
            display: none;
          }
        }
        &.is-active  {
          font-weight: bold;
          color: var(--color-text-1);
          & .detection-content__tabImg--active  {
            display: inline-block;
          }
          & .detection-content__tabImg  {
            display: none;
          }
        }
      }
    }
    & :deep(.el-tabs__content)  {
      overflow: visible;
    }
  }
}

</style>
