<template>
  <div class="sandFileUpload">
    <input
      type="file"
      id="file"
      ref="file"
      multiple
      class="sandFileUpload-input"
      @change="clickInput"
      @click="checkStatus"
    />
    <!-- <el-tooltip :disabled="canUpload" content="需要升级专业版或专业增强版" :visible-arrow="false" popper-class="fileUpload-tooltip" placement="bottom-end"> -->
    <!-- <div v-if="canUpload" @click="showUpgrade" class="sandFileUpload-labelDisable"></div> -->
    <!-- </el-tooltip> -->
    <label
      v-if="fileList.length === 0"
      for="file"
      class="sandFileUpload-drag"
      :class="{ 'sandFileUpload-labelActive': isDrag }"
      @drop="dropHandler"
      @dragover="dragOverHandler"
      @dragenter="dragenter"
      @dragleave="dragleave"
    >
      <div :class="{'img-active':isDrag}" class="sandFileUpload-drag__img"></div>
      <!-- <img class="sandFileUpload-drag__img" src="@/assets/images/detection/upload.png" /> -->
      <div class="sandFileUpload-drag__msg">{{$t('detection.tab.fileLabelText')}}</div>
    </label>
    <div
      v-if="false"
      class="sandFileUpload-showFile"
      @drop="dropHandler"
      @dragover="dragOverHandler"
      @dragenter="dragenter"
      @dragleave="dragleave"
    >
      <div class="sandFileUpload-showFile__msg" v-if="fileList.length < quotaMsg.once.value">
        {{ $t('other.views.sendfileupload.msg1') }}<label
          class="sandFileUpload-showFile__upload"
          for="file"
          >{{ $t('other.views.sendfileupload.sendfile') }}</label
        >
      </div>
      <div class="sandFileUpload-showFile__filemsg">
        <span class="sandFileUpload-showFile__filemsg--count"
          >{{ $t('other.views.sendfileupload.selected') }}{{ fileList.length }}/{{ fileCount }}{{ $t('other.views.sendfileupload.sample') }}</span
        >
        <span class="sandFileUpload-showFile__filemsg--size"
          >{{ $t('other.views.sendfileupload.total') }}{{ getSize(sizeCount) }}/{{ permiseSizeCount }}M</span
        >
      </div>
      <div class="sandFileUpload-showFile__list">
        <div class="sandFileUpload-showFile__item" v-for="(file, index) in fileList" :key="index">
          <img
            class="sandFileUpload-showFile__item--icon"
            src="assets/img/detection/file.svg"
          />
          <span class="sandFileUpload-showFile__item--name">{{ file.name }}</span>
          <img
            class="sandFileUpload-showFile__item--close"
            @click="delFile(index)"
            src="assets/img/detection/close.svg"
          />
          <span class="sandFileUpload-showFile__item--size">{{ getSize(file.size) }}</span>
        </div>
      </div>
      <el-button class="sandFileUpload-showFile-btn" @click="toConfig">{{ $t('other.views.sendfileupload.confirm') }}</el-button>
    </div>
    <!-- <upgrade-dialog v-model="isShowUpgrade" :showPoint="false" :showNormal="true"></upgrade-dialog> -->
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted, defineExpose } from 'vue'
import { toRaw } from '@vue/reactivity'
import { useStore } from 'vuex'

import { useState } from '@/hooks/useVuex'
import { detectionPermise } from './permise'
import emitter from '@/app/emitter.js'
import { checkLogin } from 'services/auth'
import { t } from 'app/i18n'
// import upgradeDialog from '@/components/common/upgradeDialog.vue'
onMounted(() => {
  setQuotaMsg()
})

const store = useStore()
const isDrag = ref(false)
// let isShowUpgrade = ref(false)
let count = 0
const file = ref(null)
const fileList = reactive([])
let quotaMsg = reactive({})
const { userInfo } = useState('user', ['userInfo'])

const loginStatus = ref(false)

// eslint-disable-next-line no-undef
const emit = defineEmits(['submit'])

const sizeCount = computed(() => {
  let count = 0
  fileList.forEach((item) => {
    count += item.size
  })
  return count.toFixed(1)
})
const fileCount = computed(() => quotaMsg.once.value)
const permiseSizeCount = computed(() => (quotaMsg.fileSize.value / 1024 / 1024).toFixed(0))

function setQuotaMsg() {
  const group = userInfo.value.group || 'visitor'
  quotaMsg = detectionPermise[group]
  for (const k in quotaMsg) {
    if (quotaMsg[k].msg) {
      quotaMsg[k].msg = t(`permise.${group}.${k}.msg`)
    }
  }
  if (userInfo.value.quota) {
    const reg = /\b\d{1,3}(,\d{3})*|\b\d+\b/g // 如果有自定义配额 替换上面循环中设置的在国际化文件中写死的固定配额
    quotaMsg.fileSize.value = userInfo.value.quota['submit.max_file_size'].quota
    quotaMsg.fileSize.msg = t('detection.file_size_limit', [`${quotaMsg.fileSize.value / 1024 / 1024}M`])
    quotaMsg.once.value = userInfo.value.quota['submit.max_count_once'].quota
    quotaMsg.once.msg = quotaMsg.minTimes.msg.replace(reg, quotaMsg.once.value)
    quotaMsg.minTimes.value = userInfo.value.quota['submit.task_frequency_1m'].quota
    quotaMsg.minTimes.msg = quotaMsg.minTimes.msg.replace(reg, quotaMsg.minTimes.value)
    quotaMsg.dayTimes.value = userInfo.value.quota['submit.task_frequency_1d'].quota
    quotaMsg.dayTimes.msg = quotaMsg.dayTimes.msg.replace(reg, quotaMsg.dayTimes.value)
    // quotaMsg.monthTimes.value = userInfo.value.quota['submit.task_frequency_30d'].quota
  }
}
function dragleave(e) {
  e.stopPropagation()
  isDrag.value = false
}
function dragenter(e) {
  e.stopPropagation()
  isDrag.value = true
}
function dragOverHandler(ev) {
  ev.preventDefault()
}
function dropHandler(ev) {
  const emntyList = []
  const files = []
  emntyList.forEach.call(ev.dataTransfer.files, (file) => {
    files.push(file)
  }, false)
  ev.preventDefault()
  isDrag.value = false
  checkLogin().then((res) => {
    if (!res.is_login) {
      emitter.emit('showLogin')
    } else {
      Array.from(files).forEach((item) => {
        handleChange(item, files.length)
      })
    }
  })
}
// 点击的上传文件
function clickInput() {
  const files = file.value.files
  Array.from(files).forEach((item) => {
    handleChange(item, files.length)
  })
}
function checkStatus(ev) {
  if (!loginStatus.value) {
    ev.preventDefault()
    // 判断登录
    checkLogin().then((res) => {
      if (!res.is_login) {
        loginStatus.value = false
        emitter.emit('showLogin')
      } else {
        loginStatus.value = true
        file.value.click()
        setTimeout(() => {
          loginStatus.value = false
        }, 200)
      }
    })
  }
}
// 文件上传判断
function handleChange(file, total) {
  // $store.commit('CLOSEMESSAGE')
  let flag = true
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)
  reader.onerror = (e) => {
    console.log(e)
    if (!e.total) {
      store.commit('app/showMessage', {
        type: 'error',
        message: t('other.views.sendfileupload.noFolder'),
        duration: 1000
      })
      flag = false
    }
  }
  reader.onloadend = (e) => {
    count += 1
    if (fileList.some((item) => item.name === file.name)) {
      store.commit('app/showMessage', {
        type: 'error',
        message: t('other.views.sendfileupload.nosamefile'),
        duration: 1000
      })
      flag = false
    }
    if (flag) {
      fileList.push({
        size: file.size,
        name: file.name,
        file: file
      })
      if (fileList.length > quotaMsg.once.value) {
        commitMsg(quotaMsg.once.msg)
      }
      if (count === total) toConfig()
    }
    if (file) {
      file.value = ''
    }
  }
}
function delFile(index) {
  fileList.splice(index, 1)
}
function toConfig() {
  // if (!wrongJudgment()) return
  store.commit('app/closeMessage')
  emit('submit', toRaw(fileList), 'file')
}
function getSize(size) {
  if (size < 1024) {
    return `${size}` + t('suffix.byte')
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)}K`
  }
  return `${(size / 1024 / 1024).toFixed(1)}M`
}
function commitMsg(msg) {
  store.commit('app/closeMessage')
  store.commit('app/showMessage', {
    type: 'error',
    message: msg,
    duration: 0,
    dangerouslyUseHTMLString: true,
    toPrice: true,
    toUpgrade: userInfo.value.group !== 'enterprise' && userInfo.value.group !== 'qihoo',
    toGroup: userInfo.value.group === 'professional' ? 'enterprise' : 'professional',
    showClose: true
  })
}

defineExpose({
  handleChange
})

</script>
<style lang="scss" scoped>
.sandFileUpload {
  position: relative;

  &-input {
    display: none;
  }

  // 拖拽上传的位置
  &-drag {
    display: block;
    height: 100px;
    cursor: pointer;
    padding: 72px 0 90px 0;

    &:hover {
      background-color:var(--detect-hover-file);
    }

    &__img {
      pointer-events: none;
      margin: 0 auto 30px auto;
      width: 64px;
      height: 64px;
      background-image: url('../../../assets/img/detection/upload.png');
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }
    .img-active {
      background-image: url('../../../assets/img/detection/upload-active.png');
    }
    &__msg {
      pointer-events: none;
      width: 100%;
      text-align: center;
    }
  }

  // 拖拽时的样式
  &-labelActive {
    background-color:var(--detect-hover-file);
  }

  &-labelDisable {
    cursor: pointer;
    // cursor not-allowed
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  // 拖拽一个后的列表页面
  &-showFile {
    display: block;
    padding: 20px 20px 30px 20px;

    &__msg {
      // pointer-events: none;
      margin-bottom: 16px;
      padding-left: 10px;
      height: 20px;
      line-height: 20px;
      position: relative;

      &:before {
        content: ' ';
        height: 6px;
        width: 6px;
        background-color: var(--color-priamry);
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 7px;
      }
    }

    // 点击上传的按钮
    &__upload {
      // pointer-events: auto;
      cursor: pointer;
      color:#537EF3;
    }

    // 文件列表的信息
    &__filemsg {
      // pointer-events: none;
      padding-left: 10px;
      padding-bottom: 10px;
      &--size {
        float: right;
      }
    }

    // 文件列表
    &__list {
      // pointer-events: none;
      max-height: 146px;
      overflow-y: auto;
    }

    // 每一项文件
    &__item {
      height: 30px;
      background-color:#202333;
      border: 1px solid var(--color-border-2);
      border-radius: 4px;
      line-height: 30px;
      padding: 0 16px;
      margin-top: 6px;

      &:hover {
        background-color: rgba(68,82,138,0.50);
        color: var(--color-text-1);
        & .sandFileUpload-showFile__item--icon {
          filter: brightness(1000%);
        }
      }

      &:first-child {
        margin-top: 0px;
      }

      &--icon {
        margin-bottom: 6px;
      }

      &--name {
        margin-left: 8px;
        max-width: 426px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--size {
        float: right;
        margin-right: 16px;
      }

      &--close {
        // pointer-events: auto;
        float: right;
        margin-top: 6px;
        cursor: pointer;

        &:hover {
          filter: brightness(1000%);
        }
      }
    }

    // &-btn {
    //   btn();
    // }
  }
}

[data-theme='light'] {
  .sandFileUpload-drag__img {
    background-image: url('../../../assets/img/detection/upload-light.png');
  }
}
</style>
