<template>
  <div class="uploadConfig">
    <div class="uploadConfig-content">
      <div
        class="uploadConfig-left"
        :class="{
          'uploadConfig-left__android': type === 'android'
        }"
      >
        <div class="leftTop">
          <div class="uploadConfig-title">
            {{$t(`detection.file_list_name.${type}`)}}
            <span class="uploadConfig-title__after" v-if="ownDataList.length > 1"
              >{{$t('detection.single_configurable_info')}}</span
            >
          </div>
          <div
            class="uploadConfig-left__list"
            :class="{ 'list-drag-active': isDrag }"
            @drop="dropHandler"
            @dragover="dragOverHandler"
            @dragenter="dragenter"
            @dragleave="dragleave"
          >
              <el-tooltip
                v-for="(item, index) in ownDataList"
                :key="index"
                :disabled="!item.errMsg"
                class="item"
                :effect="$isDark() ? 'dark' : 'light'"
                :content="item.errMsg && item.errMsg.msg || ''"
                placement="bottom"
                popper-class="errorMsgPopper"
              >
                  <div
                    class="uploadConfig-left__item"
                    :class="{
                      'uploadConfig-left__itemActive': activeIndex === index,
                      'wrong' : item.status === 'wrong',
                      'success' : item.status === 'success'
                    }"
                    @click="editItem(index)"
                  >
                    <div class="pro" :style="{width: `${item.progress}%`}"></div>
                    <div class="items">
                      <img class="uploadConfig-left__item--icon" :src="getTypeIcon(item, typeName)" />
                      <span class="uploadConfig-left__item--name">{{
                        type === 'file' || type === 'android' || type === 'linux' ? item.name : item.value
                      }}</span>
                      <el-icon class="item-delete" @click.stop="deleteItems(index)"><CircleClose /></el-icon>
                      <!-- <i @click.stop="deleteItems(index)" class="item-delete el-icon-circle-close"></i> -->
                      <img
                        v-if="configList[index].useBase && ownDataList.length > 1"
                        class="uploadConfig-left__item--configIcon"
                        src="@/assets/img/detection/fileConfig.png"
                      />
                      <img
                        v-if="!configList[index].useBase && ownDataList.length > 1"
                        class="uploadConfig-left__item--configIconActive"
                        src="@/assets/img/detection/fileConfigActive.png"
                      />
                      <span class="uploadConfig-left__item--status">{{getStatus(item,index)}}</span>
                      <span
                        v-if="item.size && item.fileType !== 'URL'"
                        class="uploadConfig-left__item--size"
                        >{{ getSize(item.size) }}</span
                      >
                      <span class="uploadConfig-left__item--fileType">{{item.fileType || ''}}</span>
                    </div>
                  </div>
               </el-tooltip>
            <div class="dragPlaceholder"><el-icon><plus /></el-icon>{{$t('detection.drag_add_file')}}</div>
          </div>
          <template v-if="type === 'file'">
            <input
              type="file"
              id="file"
              ref="file"
              multiple
              style="display:none"
              class="sandFileUpload-input"
              @change="clickInput"
            />
            <div class="uploadConfig-left--info">
              <p>
                <span>{{$d('file_total_status.size')}}: {{getSize(sizeCount)}}</span>
                <span>{{$d('file_total_status.num')}}: {{ownDataList.length}}</span>
                <span>{{$d('file_total_status.uploaded')}}: {{getDownloadedCount()}}</span>
                <span>{{$d('file_total_status.uploading')}}: {{getDownloadingCount()}}</span>
                <span>{{$d('file_total_status.failed')}}: {{getDownloadedFailedCount()}}</span>
              </p>
              <label for="file"> <div class="addFile"><el-icon><plus /></el-icon>{{$d('add_file')}}</div></label>
            </div>
          </template>
          <template v-else>
            <div class="uploadConfig-left--info uploadConfig-left--info-url">
              <p v-if="type === 'url'">
                <span>{{$d('url_total_status.url_num')}}: {{ownDataList.filter(v=>v.fileType === 'URL').length}}</span>
                <span>{{$d('url_total_status.verified')}}: {{getDownloadedCount('url')}}</span>
                <span>{{$d('url_total_status.verifying')}}: {{getDownloadingCount('url')}}</span>
                <span>{{$d('url_total_status.failed')}}: {{getDownloadedFailedCount('url')}}</span>
              </p>
              <p>
                <span>{{$d('url_total_status.size')}}: {{getSize(sizeCount)}}</span>
                <span>{{$d('url_total_status.file_num')}}: {{ownDataList.filter(v=>v.fileType !== 'URL').length}}</span>
                <span>{{$d('url_total_status.downloaded')}}: {{getDownloadedCount('file')}}</span>
                <span>{{$d('url_total_status.downloading')}}: {{getDownloadingCount('file')}}</span>
                <span>{{$d('url_total_status.download_failed')}}: {{getDownloadedFailedCount('file')}}</span>
              </p>
            </div>
            <div
              class="sandUrlUpload-content"
              :class="{'sandUrlUpload-content--focus': isFocus}">
              <el-input
                class="sandUrlUpload-input"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                :placeholder="$d(`textMap.${type}.placeholder`)"
                @keyup.enter="keyupEnter"
                v-model="textarea"
                resize="none"
                maxlength="2048"
                @focus="inputFocus"
                @blur="inputBlur">
              </el-input>
              <el-icon class="icon-right" :color="$isDark() ? '#fff' : '#242933'" v-show="textarea" @click="keyupEnter"><Right /></el-icon>
            </div>
          </template>
        </div>
        <div class="leftBottom">
          <div class="private">
            <div class="uploadConfig-left__radio" @click="showUpgrade">
              <span class="uploadConfig-left__radio--label" @click.stop>{{$d('permissions.label')}}</span>
              <el-switch
                :disabled="!userInfo.group || userInfo.group === 'free'"
                v-model="is_public"
                :active-value='true'
                :inactive-value='false'
                size="small"
                active-color="#FA8C16"
                inactive-color="#00ab7a"
                :active-text="$d('permissions.active')"
                :inactive-text="$d('permissions.inactive')">
              </el-switch>
            </div>
            <div v-if="loginState && teamList.length > 0" class="uploadConfig-left__select">
              <span class="uploadConfig-left__select--label" @click.stop>{{$d('share_team.label')}}</span>
              <el-select
                multiple
                collapse-tags
                :suffix-icon="arrow"
                v-model="selectTeam"
                :placeholder="$d('share_team.placeholder')"
                popper-class="detect-operate"
              >
                <el-option
                  v-for="item in teamList"
                  :key="item.value"
                  :label="item.team_name"
                  :value="item.team_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <el-checkbox class="uploadConfig-left__checkbox" v-model="isChecked">
            <i18n-t keypath="detection.agreement" tag="span">
              <template #user>
                <router-link to="/help/docs/terms">{{$d('user')}}</router-link>
              </template>
              <template #privacy>
                <router-link to="/help/docs/privacy">{{$d('privacy')}}</router-link>
              </template>
            </i18n-t>
          </el-checkbox>
          <p class="uploadConfig-left__warning" :style="{visibility:getDownloadedFailedCount()?'visible':'hidden'}"><svg-icon name="info"></svg-icon>  {{$t('detection.exsit_err_item', [type === 'file' ? $d('err_text') : $d('err_url_text')])}}</p>
          <div class="btns">
            <el-button class="uploadConfig-btn" :disabled="failedCount || platform.length==0" @click="submit"
            >{{$d('submit')}}</el-button
            >
            <el-button @click="deleteAllErrorItems" :disabled="!isHaveFailedItem" class="uploadConfig-clear-btn">{{$d('clean_up')}}</el-button>
          </div>
          <!-- <div v-else class="uploadConfig-progress">
            <div ref="progress" class="uploadConfig-progress__inner"></div>
            <div class="uploadConfig-progress__text">上传中 {{ progress }}%</div>
          </div> -->
        </div>
      </div>
      <div v-if="type !== 'android'" class="uploadConfig-right">
        <div class="uploadConfig-title">
          <span v-show="activeIndex !== null" class="uploadConfig-title__before" @click="toOverall">
            {{$d('back_global')}}
          </span>
          <!-- <span v-show="activeIndex !== null" class="uploadConfig-title__before--line">|</span> -->
          {{ ownDataList.length > 0 ? (activeIndex === null ? $d('gloabal_configuration') : $d('single_configuration')) : '' }}
          <span class="uploadConfig-title__before--line" v-if="platform.length>0">|</span>
          <span class="uploadConfig-title__platform" @click="curPlatform=item" :class="{'active':curPlatform == item}" v-for="(item, index) in platform" :key="index">{{item}}</span>
          <!-- <div v-if="usePromiseName" class="uploadConfig-title__openIntegral">
            已启用{{ usePromiseName }}任务配置权益
          </div> -->
        </div>
        <div v-if="activeIndex !== null" class="uploadConfig-right__file" :class="useBase && 'active'">
          <span v-if="type === 'file' || type === 'linux'">{{
            activeIndex !== null ? ownDataList[activeIndex].name : ''
          }}</span>
          <span v-else>{{ activeIndex !== null ? ownDataList[activeIndex].value : '' }}</span>
          <el-checkbox
            v-if="platform.length>0"
            class="uploadConfig-right__file--checkbox"
            v-model="useBase"
            @change="changeUseBase"
            >{{$d('use_gloabal_configuration')}}</el-checkbox
          >
        </div>
        <div class="fileConfig_line" v-if="!useBase"></div>
        <div
          ref="rightConfig"
          class="uploadConfig-right__config"
          :class="{ 'uploadConfig-right__config--disable': useBase }"
          v-show="platform.length>0"
        >
          <configBox
            @inputError="inputErrorChange"
            ref="configBoxRef"
            :configData="useConfig"
            @update:configData="updateconfigData"
            @delete:configData="deleteconfigData"
            :activeIndex="activeIndex"
            :type="type"
            @change="changeConfig"
            :configUserPromise="useConfigUserPromise"
            :useIntegral="!!userInfo.group"
            :taskType="curPlatform"
            :curFileType="fileType"
            :dataList="ownDataList"
            :includeUrlAndFile="includeUrlAndFile"
          ></configBox>
        </div>
        <div v-show="platform.length==0" style="padding:18px">{{errText}}</div>
      </div>
    </div>
    <!-- <upgrade-dialog v-model="isShowUpgrade" :showPoint="true" :showNormal="true"></upgrade-dialog> -->
  </div>
  <publishDialog
    v-if="isShowPublish"
    v-model:show="isShowPublish"
    @ok="submit(true)"
  />
  <template v-if="showErrPage">
    <captch-dialog :captchDialogVisible="captchDialogVisible" @close="closeCaptchDialogHandle"></captch-dialog>
    <!-- <close-captch v-if="!captchDialogVisible" @showCaptch="captchDialogVisible = true"></close-captch> -->
  </template>
</template>

<script setup>
import cloneDeep from 'lodash/cloneDeep'
import configBox from './configBox'
import captchDialog from '@/components/common/captchDialog.vue'
import publishDialog from '@/components/common/publishDialog.vue'
// import closeCaptch from '@/components/common/closeCaptch.vue'
import { postTaskSubmitSingle, postUploadSample, checkUploadSample, getUrlState } from 'services/detection'
// import upgradeDialog from '@/components/common/upgradeDialog.vue'
import { checkLogin } from 'services/auth'
import { useStore } from 'vuex'
import { ref, unref, reactive, watch, computed, onMounted, defineProps, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { detectionPermise, uploadStatus } from './permise'
import { useState, useGetters } from '@/hooks/useVuex'
import { CircleClose, Plus, Right } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import arrow from 'components/common/arrow'
import emitter from 'app/emitter'
import { t } from 'app/i18n'

const selectTeam = ref([])
const isShowUpgrade = ref(false)
const isShowPublish = ref(false)
const is_public = ref(false)
const activeIndex = ref(null)
const isChecked = ref(false)
const baseConfig = {}
const curPlatform = ref('')
const isFocus = ref(false)
const textarea = ref('')
const isDrag = ref(false)
const usePromiseName = ref('')
const inputError = ref('')
const isSubmit = ref(false)
const configUserPromise = ref({})
const file = ref(null)
const rightConfig = ref(null)
const configBoxRef = ref(null)

const captchDialogVisible = ref(false)

const store = useStore()
const router = useRouter()
const { userInfo, scoreInfo } = useState('user', ['userInfo', 'scoreInfo'])

const { teamList } = useGetters('team', ['teamList'])

const loginState = ref(store.getters.isLogin)

const gid = store.getters.gid

const Linux = ['elf', 'shell']

const showErrPage = ref(false)

const useConfig = computed({
  get: () => unref(activeIndex) === null ? baseConfig[curPlatform.value] : configList[activeIndex.value],
  set: val => {
    unref(activeIndex) === null ? baseConfig[curPlatform.value] = val : configList[activeIndex.value] = val
  }
})

const useConfigUserPromise = computed({
  get: () => activeIndex.value === null ? configUserPromise.value : configList[activeIndex.value].configUserPromise,
  set: val => {
    activeIndex.value === null ? configList[activeIndex.value].configUserPromise = val : configUserPromise.value = val
  }
})

function updateconfigData(key, val) {
  unref(activeIndex) === null ? baseConfig[curPlatform.value][key] = val : configList[activeIndex.value][key] = val
}

function deleteconfigData(key) {
  unref(activeIndex) === null ? delete baseConfig[curPlatform.value][key] : delete configList[activeIndex.value][key]
}

const useBase = computed(() => activeIndex.value === null ? false : configList[activeIndex.value].useBase)

// 把用户的权益转换成数值
const getUserLevel = computed(() => userInfo.value.group === 'professional' ? 2 : userInfo.value.group === 'enterprise' || userInfo.value.group === 'qihoo' ? 3 : 1)
const getStatus = computed(() => {
  return (item) => {
    const status = item.status
    const progress = item?.progress || 0
    if (item.unlegal) {
      return t('detection.type_error')
    } else if (item.checkResult) {
      return t(`detection.check_enums.${item.checkResult}`) || ''
    } else {
      if (status === 'success') return t(`detection.file_upload_status.${props.type}.success`)
      if (status === 'wrong') return t(`detection.file_upload_status.${props.type}.failed`)
    }
    return `${t(`detection.running_map.${props.type}`)} ${progress}%`
  }
})

const $d = computed(params => {
  return (params) => {
    return t(`detection.${params}`)
  }
})

const fileType = computed(() => {
  if (!ownDataList.value.length) return ''
  return (ownDataList.value[activeIndex.value] && ownDataList.value[activeIndex.value]?.fileType) || ownDataList.value[0].fileType || ''
})

const includeUrlAndFile = computed(() => {
  if (!ownDataList.value.length) return false
  return ownDataList.value.some(v => v.fileType === 'URL') && ownDataList.value.some(v => v.fileType !== 'URL')
})

const isHaveFailedItem = computed(() => {
  return ownDataList.value.some(v => (v.status === 'wrong' || v.errMsg))
})

const getDownloadedCount = computed(() => {
  return (type = 'total') => {
    const arr = type === 'total' ? ownDataList.value : type === 'file' ? ownDataList.value.filter(v => v.fileType !== 'URL') : ownDataList.value.filter(v => v.fileType === 'URL')
    return arr.filter(v => v.status !== 'wrong' && v.progress === '100').length
  }
})

const getDownloadingCount = computed(() => {
  return (type = 'total') => {
    const arr = type === 'total' ? ownDataList.value : type === 'file' ? ownDataList.value.filter(v => v.fileType !== 'URL') : ownDataList.value.filter(v => v.fileType === 'URL')
    if (type === 'file') return arr.filter(v => v.checkResult === 'downloading').length
    return arr.filter(v => Number(v.progress) && Number(v.progress) > 0 && Number(v.progress) < 100).length
  }
})

const getDownloadedFailedCount = computed(() => {
  return (type = 'total') => {
    const arr = type === 'total' ? ownDataList.value : type === 'file' ? ownDataList.value.filter(v => v.fileType !== 'URL') : ownDataList.value.filter(v => v.fileType === 'URL')
    if (type === 'file') return arr.filter(v => v.status === 'wrong' || v.errMsg).length
    return arr.filter(v => (v.status !== 'success' || v.errMsg) && v.done).length
  }
})

const sizeCount = computed(() => ownDataList.value.filter((item) => item.size).reduce((t, c) => (t += c.size), 0))

const failedCount = computed(() => !!getDownloadedFailedCount.value() || !!getDownloadingCount.value())
// const diffOwnDataList = computed(() => cloneDeep(unref(ownDataList)))

const platform = computed(() => {
  let arr = []
  const sort = ['Windows', 'Linux', 'Android']
  unref(ownDataList.value).map(v => {
    if (!v.errMsg && v.platform && !arr.includes(v.platform)) {
      arr.push(v.platform)
    }
  })
  arr.sort((a, b) => {
    return sort.indexOf(a) - sort.indexOf(b)
  })
  if (activeIndex.value !== null) {
    const v = ownDataList.value[activeIndex.value]
    if (v.status !== 'wrong' && v.progress === '100') {
      arr = [v.platform]
    } else {
      arr = []
    }
  }
  return arr
})

const errText = computed(() => {
  let text = t('detection.errText.empty')
  if (activeIndex.value === null) return text
  const v = ownDataList.value[activeIndex.value]
  let type = v.fileType !== 'URL' ? t('detection.slot.download') : t('detection.slot.verify')
  type = props.type === 'file' ? t('detection.slot.upload') : type
  if ((v.status !== 'success' && v.progress === '100') || v.errMsg) {
    text = t('detection.errText.failed', [type])
  } else if (Number(v.progress) && Number(v.progress) > 0 && Number(v.progress) < 100) {
    text = t('detection.errText.progress', [type])
  }
  // todo 等上传接口定义好数据格式
  return text
})

const props = defineProps({
  type: {
    type: String,
    default: 'file'
  },
  dataList: {
    type: Array,
    default: () => []
  }
})

const configList = reactive(props.dataList.map((item) => ({
  strategy: 2,
  useBase: true,
  urlObjectConfig: 'browser',
  env_var: [],
  timeout: 120,
  internet: true,
  decrypt_https: gid > 1,
  human: true,
  human_popbox: true,
  human_scroll: true,
  human_curpos: true,
  human_autoin: false,
  fake_reboot: true,
  fast_detect: true,
  human_clipbd: true,
  pwd_forced: true,
  useNetCheckbox: true,
  emailer: 'extract',
  office: 'mso',
  type_in_zip: 'md5_in_zip',
  netRadio: 'tor',
  configUserPromise: {}
})))

// const ownDataList = reactive({
//   data: props.dataList
// })

// const state = reactive({
//   ownDataList: props.dataList
// })

// const { ownDataList } = toRefs(state)
const ownDataList = ref(props.dataList)

let quotaMsg = {}
const regMap = {
  hash: {
    // placeholder: '输入或粘贴 MD5、SHA1或SHA256，批量提交时，多个hash之间需换行',
    reg: /(^[a-fA-F0-9]{32}$)|(^[a-fA-F0-9]{40}$)|(^[a-fA-F0-9]{64}$)/
  },
  url: {
    reg: /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+.+$/
  }
}

const initOwnDataList = []

function getSize(size) {
  if (isNaN(size)) {
    return `0${t('byte')}`
  }
  if (size < 1024) {
    return `${size}${t('byte')}`
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)}K`
  }
  return `${(size / 1024 / 1024).toFixed(1)}M`
}
function clickInput() {
  const files = file.value.files
  Array.from(files).forEach((item) => {
    handleChange(item)
  })
}
async function keyupEnter (e) {
  if (textarea.value === '') return
  let inputList = textarea.value.split('\n').map(item => item.trim()).filter(item => item !== '')
  const length = inputList.length
  if (length === 0) {
    textarea.value = ''
    return
  }
  inputList = [...new Set(inputList)]
  // 重复的给提示
  if (length !== inputList.length) {
    store.commit('app/showMessage', { type: 'error', message: t(`detection.textMap.${props.type}.msg3`), duration: 1000, offset: 60 })
  }
  // 过滤和已经添加了的相同的
  if (inputList.some(item => ownDataList.value.some(i => i.value.toLowerCase() === item.toLowerCase()))) {
    inputList = inputList.filter(item => ownDataList.value.every(i => i.value.toLowerCase() !== item.toLowerCase()))
    store.commit('app/showMessage', { type: 'error', message: t(`detection.textMap.${props.type}.msg3`), duration: 1000, offset: 60 })
  }
  // 单独考虑hash
  if (props.type === 'hash') {
    inputList.map(item => {
      handleChange({
        type: regMap[props.type].reg.test(item.toLowerCase()),
        value: item,
        name: item
      })
    })
    textarea.value = ''
    if (ownDataList.value.length > quotaMsg.once.value) {
      commitMsg(quotaMsg.once.msg)
    }
    return
  }
  // 判断url错误
  await getUrlState({
    urls: inputList
  }).then(res => {
    for (const key in res) {
      handleChange({
        type: key.length < 1990 && res[key],
        value: key,
        name: key
      })
    }
    textarea.value = ''
  }).catch(() => {
    store.commit('app/showMessage', { type: 'error', message: t('http.timeout'), duration: 1000, offset: 60 })
  })
}
function addConfig() {
  configList.push({
    strategy: 2,
    useBase: true,
    urlObjectConfig: 'browser',
    env_var: [],
    timeout: 120,
    internet: true,
    decrypt_https: gid > 1,
    human: true,
    human_popbox: true,
    human_scroll: true,
    human_curpos: true,
    human_autoin: false,
    fake_reboot: true,
    fast_detect: true,
    human_clipbd: true,
    pwd_forced: true,
    useNetCheckbox: true,
    emailer: 'extract',
    office: 'mso',
    type_in_zip: 'md5_in_zip',
    netRadio: 'tor',
    configUserPromise: {}
  })
}
function wrongJudgment(init) {
  const arr = !init ? ownDataList.value : initOwnDataList
  if (!userInfo.value || !userInfo.value.quota) return null
  if (arr.length >= userInfo.value.quota['submit.max_count_once'].quota) {
    console.log(quotaMsg.once.msg)
    commitMsg(quotaMsg.once.msg.replace('{count}', userInfo.value.quota['submit.max_count_once'].quota + ''))
    return { msg: quotaMsg.once.msg.replace('{count}', userInfo.value.quota['submit.max_count_once'].quota + '') }
  }
  const count1m = userInfo.value.quota_used
    ? userInfo.value.quota['submit.task_frequency_1m'].quota_used
    : 0
  const count1d = userInfo.value.quota_used
    ? userInfo.value.quota['submit.task_frequency_1d'].quota_used
    : 0
  // const count30d = userInfo.value.quota_used
  //   ? userInfo.value.quota['submit.task_frequency_30d'].quota_used
  //   : 0
  if (arr.length + count1m >= userInfo.value.quota['submit.task_frequency_1m'].quota) {
    commitMsg(quotaMsg.minTimes.msg.replace('{count}', userInfo.value.quota['submit.task_frequency_1m'].quota + ''))
    return { msg: quotaMsg.minTimes.msg.replace('{count}', userInfo.value.quota['submit.task_frequency_1m'].quota + '') }
  }
  if (arr.length + count1d >= userInfo.value.quota['submit.task_frequency_1d'].quota) {
    commitMsg(quotaMsg.dayTimes.msg.replace('{count}', userInfo.value.quota['submit.task_frequency_1d'].quota + ''))
    return { msg: quotaMsg.dayTimes.msg.replace('{count}', userInfo.value.quota['submit.task_frequency_1d'].quota + '') }
  }
  // if (arr.length + count30d >= quotaMsg.monthTimes.value) {
  //   commitMsg(quotaMsg.monthTimes.msg)
  //   return { msg: quotaMsg.monthTimes.msg }
  // }
  return null
}
function setQuotaMsg() {
  const group = userInfo.value.group || 'visitor'
  quotaMsg = detectionPermise[group]
  for (const k in quotaMsg) {
    if (quotaMsg[k].msg) {
      quotaMsg[k].msg = t(`permise.${group}.${k}.msg`)
    }
  }
  if (userInfo.value.quota) {
    const reg = /\b\d{1,3}(,\d{3})*|\b\d+\b/g // 如果有自定义配额 替换上面循环中设置的在国际化文件中写死的固定配额
    quotaMsg.fileSize.value = userInfo.value.quota['submit.max_file_size'].quota
    quotaMsg.fileSize.msg = t('detection.file_size_limit', [`${quotaMsg.fileSize.value / 1024 / 1024}M`])
    quotaMsg.once.value = userInfo.value.quota['submit.max_count_once'].quota
    quotaMsg.once.msg = quotaMsg.minTimes.msg.replace(reg, quotaMsg.once.value)
    quotaMsg.minTimes.value = userInfo.value.quota['submit.task_frequency_1m'].quota
    quotaMsg.minTimes.msg = quotaMsg.minTimes.msg.replace(reg, quotaMsg.minTimes.value)
    quotaMsg.dayTimes.value = userInfo.value.quota['submit.task_frequency_1d'].quota
    quotaMsg.dayTimes.msg = quotaMsg.dayTimes.msg.replace(reg, quotaMsg.dayTimes.value)
    // quotaMsg.monthTimes.value = userInfo.value.quota['submit.task_frequency_30d'].quota
  }
}
function dragleave(e) {
  e.stopPropagation()
  isDrag.value = false
}
function dragenter(e) {
  if (props.type !== 'file') return
  e.stopPropagation()
  isDrag.value = true
}
function dragOverHandler(ev) {
  ev.preventDefault()
}
function dropHandler(ev) {
  if (props.type !== 'file') return
  ev.preventDefault()
  isDrag.value = false
  const files = ev.dataTransfer.files
  Array.from(files).forEach((item) => {
    handleChange(item)
  })
}
function inputFocus () {
  isFocus.value = true
}
function inputBlur () {
  isFocus.value = false
}
async function handleChange(file) {
  const fileObj = await handleFiles(file)
  ownDataList.value.push(fileObj)
  addConfig()
  addFile(fileObj)
}
async function initChange(item) {
  const fileObj = await handleFiles(item, true)
  initOwnDataList.push(fileObj)
  addFile(fileObj)
  return fileObj
}
function handleFiles(item, init = false) {
  store.commit('app/closeMessage')
  let fileObj
  let errMsg
  if (props.type === 'file') {
    const file = init ? item.file : item
    let flag = true
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onerror = (e) => {
      store.commit('app/showMessage', {
        type: 'error',
        message: t('detection.folder_limit'),
        duration: 1000,
        offset: 60
      })
      flag = false
    }
    const result = new Promise(function(resolve, reject) {
      reader.onloadend = (e) => {
        const size = file.size
        if (size === 0) {
          store.commit('app/showMessage', {
            type: 'error',
            message: t('detection.empty_limit.message'),
            duration: 1000,
            offset: 60
          })
          errMsg = {
            code: 102,
            msg: t('detection.empty_limit.tooltip')
          }
          flag = false
        }
        // if (sizeCount.value + size > quotaMsg.fileSize.value) {
        //   store.commit('app/showMessage', {
        //     type: 'error',
        //     message: '文件总大小超出提交限额',
        //     duration: 1000,
        //     offset: 60
        //   })
        // }
        // const arr = !init ? ownDataList.value : initOwnDataList
        // arr.forEach((item) => {
        //   size += item.size
        // })
        if (size > quotaMsg.fileSize.value) {
          commitMsg(quotaMsg.fileSize.msg)
          errMsg = {
            code: 105,
            msg: t('detection.file_size_limit')
          }
          flag = false
        }
        if (!init && ownDataList.value.some((item) => item.name === file.name)) {
          store.commit('app/showMessage', {
            type: 'error',
            message: t('detection.duplicated_name_limit'),
            duration: 1000,
            offset: 60
          })
          return
        }
        if (ownDataList.value.length > quotaMsg.once.value) {
          commitMsg(quotaMsg.once.msg)
        }
        if (flag) {
          errMsg = wrongJudgment(init)
        }
        item.errMsg = errMsg
        item = {
          size: file.size,
          name: file.name,
          file: file,
          done: true,
          errMsg
        }
        resolve(item)
      }
    })
    return result
  } else {
    const result = new Promise(function(resolve, reject) {
      setTimeout(() => {
        if (!item.type) {
          store.commit('app/showMessage', { type: 'error', message: t(`detection.textMap.${props.type}.msg3`), duration: 1000, offset: 60 })
          errMsg = {
            code: 107,
            msg: t(`detection.textMap.${props.type}.msg2`)
          }
          item.unlegal = true
        } else {
          if (ownDataList.value.length > quotaMsg?.once?.value) {
            commitMsg(quotaMsg.once.msg)
            item.fileType = 'URL'
            item.checkResult = 'verify_failed'
          }
          errMsg = wrongJudgment(init)
        }
        fileObj = {
          ...item,
          size: null,
          name: item.value,
          done: true,
          errMsg
        }
        resolve(fileObj)
      }, 300)
    })
    return result
  }
}
function commitMsg(msg) {
  store.commit('app/closeMessage')
  store.commit('app/showMessage', {
    type: 'error',
    message: msg,
    duration: 0,
    dangerouslyUseHTMLString: true,
    toPrice: true,
    toUpgrade: userInfo.value.group !== 'enterprise' && userInfo.value.group !== 'qihoo',
    toGroup: userInfo.value.group === 'professional' ? 'enterprise' : 'professional',
    showClose: true,
    offset: 60
  })
}
function deleteItems(index) {
  activeIndex.value = null
  ownDataList.value.splice(index, 1)
  configList.splice(index, 1)
}
function deleteAllErrorItems() {
  activeIndex.value = null
  ownDataList.value = ownDataList.value.filter((v, index) => {
    if (v.status !== 'wrong') {
      configList.filter((item, idx) => idx === index)
      return v
    }
  })
}
// 返回提交分析
function toDetection() {
  router.go(0)
}
function changeUseBase(data) {
  configList[activeIndex.value].useBase = data
}
function getTypeIcon(item, typeName) {
  if (!item.platform) {
    return require(`@/assets/img/detection/${typeName === 'file' ? 'unkown_icon' : 'download'}.svg`)
  } else {
    let src
    switch (item.platform) {
      case 'Windows':
        src = 'windows_success_icon'
        break
      case 'Linux':
        src = 'linux_success_icon'
        break
      case 'Android':
        src = 'android_success_icon'
        break
      default: src = ''
        break
    }
    return require(`@/assets/img/detection/${src}.svg`)
  }
}
function editItem(index) {
  if (ownDataList.value.length <= 1) return
  if (inputError.value) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.err_config_item'),
      duration: 1000,
      showClose: true,
      offset: 60
    })
    return
  }
  activeIndex.value = index
  configBoxRef.value.isOpen = []
  rightConfig.value.style.height = 'calc(100% - 102px)'
  rightConfig.value.scrollTo(0, 0)
}
function toOverall() {
  if (inputError.value) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.err_config_item'),
      duration: 1000,
      showClose: true,
      offset: 60
    })
    return
  }
  activeIndex.value = null
  configBoxRef.value.isOpen = []
  rightConfig.value.style.height = 'calc(100% - 51px)'
  rightConfig.value.scrollTo(0, 0)
}
function changeConfig() {
  // 找到最大的权限是多大
  const promise = Math.max(1, ...Object.values(useConfigUserPromise.value))
  // 如果超过了现有的就显示开启配置权益
  if (promise > getUserLevel.value) {
    // 由于用户现在只有专业版和增强版中的一个，所以通过他有的权限来判断，暂时不通过他使用的权限来判断
    // 为了防止用户只有专业增强显示使用的是专业
    if (scoreInfo.value?.detection_pro_last > 0) {
      usePromiseName.value = '专业版'
    }
    if (scoreInfo.value?.detection_enter_last > 0) {
      usePromiseName.value = '专业增强版'
    }
    return userInfo.value.group
    // // 判断开启的哪一档
    // if (promise === 2) {
    //   usePromiseName = '专业版'
    // } else {
    //   usePromiseName = '专业增强版'
    // }
  } else {
    usePromiseName.value = ''
  }
}
function inputErrorChange(isErr) {
  inputError.value = isErr
}
function resetData(configs, global = true) {
  if (global) {
    for (const key in configs) {
      const data = configs[key]
      reset(data)
    }
    return configs
  } else {
    return reset(configs)
  }
  function reset(data) {
    if (!data.useNetCheckbox) {
      delete data.netRadio
      delete data.tor
      delete data.vpn
      delete data.proxy
      delete data.userRequiredCheckbox
      delete data.username
      delete data.password
    }
    if (data.netRadio === 'tor') {
      delete data.vpn
      delete data.proxy
      delete data.userRequiredCheckbox
      delete data.username
      delete data.password
    } else if (data.netRadio === 'vpn') {
      delete data.tor
      delete data.proxy
      delete data.userRequiredCheckbox
      delete data.username
      delete data.password
    } else if (data.netRadio === 'proxy') {
      delete data.tor
      delete data.vpn
    }
    if (!data.userRequiredCheckbox) {
      delete data.username
      delete data.password
    }
    if (data.urlObjectConfig === 'browser') delete data.user_agent
    else delete data.browser
    delete data.useNetCheckbox
    delete data.userRequiredCheckbox
    delete data.netRadio
    delete data.urlObjectConfig
    const envVarList = []
    data.env_var.forEach((i) => {
      if (i.key !== '' && i.value !== '') {
        envVarList.push(`${i.key}=${i.value}`)
      }
    })
    data.env_var = envVarList
    delete data.useBase
    if (!data.custom_detect_type) {
      delete data.custom_detect_type
    }
    if (!data.runpath) {
      delete data.runpath
    }
    if (!data.timezone) {
      delete data.timezone
    }
    if (!data.geo) {
      delete data.geo
    }
    return data
  }
}
function addFile(item) {
  const findIndex = ownDataList.value.findIndex(v => v.name === item.name)
  if (item.errMsg) {
    item.status = 'wrong'
    ownDataList.value[findIndex] = reactive({ ...item })
  } else {
    const formData = new FormData()
    if (props.type === 'file') {
      formData.append('sample', item.file)
    } else if (props.type === 'url') {
      formData.append('url', item.value)
    } else if (props.type === 'hash') {
      formData.append('hash', item.value)
    }
    formData.append(
      'category',
      props.type === 'hash'
        ? 'hash'
        : props.type === 'android' || props.type === 'linux'
          ? 'file'
          : props.type
    )
    // for (var value of formData.values()) {
    //   console.log(value)
    // }
    postUploadSample(formData, (data) => {
      if (props.type === 'file') {
        const progress = ((data.loaded / data.total) * 100).toFixed(0)
        item.progress = !isNaN(parseFloat(progress)) ? progress : 0
        ownDataList.value[findIndex] = reactive({ ...item })
      }
    }).then(res => {
      // res.code = 2
      // 0成功 1失败
      // url或者hash成功失败逻辑移到check接口
      if (props.type === 'file') {
        const result = res[0][item.name]
        const bol = result.status
        let errMsg = null
        item.status = bol !== 200 ? 'wrong' : 'success'
        if (bol !== 200) {
          errMsg = {
            code: bol,
            msg: t(`detection.uploadStatus[${bol}]`)
          }
        }
        item.errMsg = errMsg
        // 文件类型下载upload完确认文件类型    url，hash在check时
        item.fileType = result.file_type
        // const pattern = /\.{1}[A-Za-z]{1,}$/
        // const fileType = file.name.substring(pattern.exec(file.name).index + 1, file.name.length)
        item.platform = item.fileType === 'apk' ? 'Android' : Linux.includes(item.fileType) ? 'Linux' : 'Windows'

        item.filename = result.filename
        item.file_size = result.file_size
        item.md5 = result.md5
        item.sha1 = result.sha1
        item.sha256 = result.sha256
        ownDataList.value[findIndex] = reactive({ ...item })
      } else {
        // upload后同步调check获取下载进度
        // url和hash需要check
        const type = props.type === 'hash'
          ? 'hash'
          : props.type === 'android' || props.type === 'linux'
            ? 'file'
            : props.type
        const data = {
          category: type
        }
        data[type] = item.value
        checkUploadSampleFn(data, item, findIndex)
      }
    }).catch(e => {
      item.status = 'wrong'
      item.errMsg = {
        code: 401,
        msg: uploadStatus[401]
      }
      ownDataList.value[findIndex] = reactive({ ...item })
    }).finally(() => {
      item.done = true
    })
  }
}

const checkStatusMap = {
  verify_failed: t('detection.verify_failed'),
  download_failed: t('detection.download_failed'),
  verify_failed_type_not_found: t('detection.verify_failed_type_not_found'),
  content_to_large: t('detection.content_to_large')
}

function checkUploadSampleFn(data, item, findIndex) {
  checkUploadSample(data).then(res => {
    const result = res[0][item.name]
    let timer
    if (result.status === 'downloading' || result.status === 'verifying') {
      if (result.size && result.total_size) {
        const progress = ((result.size / result.total_size) * 100).toFixed(0)
        item.progress = (!isNaN(parseFloat(progress)) && progress !== 'Infinity') ? progress : 0
        ownDataList.value[findIndex] = reactive({ ...item })
      }
      timer = setTimeout(() => { checkUploadSampleFn(data, item, findIndex) }, 1000)
      return
    } else {
      clearTimeout(timer)
      timer = null
      item.done = true
    }
    const successArr = ['downloaded', 'verified']
    item.progress = '100'
    item.checkResult = result.status
    item.size = Number(result.total_size) || 0
    const urlStatus = ['verified', 'verify_failed', 'verify_failed_type_not_found', 'verified']
    item.fileType = urlStatus.includes(result.status) ? 'URL' : result.file_type
    item.platform = item.fileType === 'apk' ? 'Android' : Linux.includes(item.fileType) ? 'Linux' : 'Windows'
    let errMsg = null
    const bol = successArr.includes(result.status)
    item.status = bol ? 'success' : 'wrong'
    if (result.error_code) {
      errMsg = {
        code: result.error_code,
        msg: t(`detection.uploadStatus[${result.error_code}]`)[result.error_code] || result.error_message
      }
    } else {
      for (const key in checkStatusMap) {
        if (result.status === key) {
          errMsg = {
            code: bol,
            msg: checkStatusMap[key]
          }
        }
      }
      if (/^\d+$/.test(result.status)) {
        errMsg = {
          code: result.status,
          msg: uploadStatus[result.status]
        }
      }
    }
    item.errMsg = errMsg
    ownDataList.value[findIndex] = reactive({ ...item })
  })
}

function submitData(token) {
  const configListCopy = configList.map((item) => {
    if (item.useBase) {
      return resetData(cloneDeep(baseConfig))
    }
    return resetData(cloneDeep(item), false)
  })
  // console.log(JSON.stringify(configList))
  // isSubmit = false
  // return
  const formData = new FormData()
  const config = {}
  ownDataList.value.forEach((item, index) => {
    const configData = 'Windows' in configListCopy[index] ? configListCopy[index][item.platform] : configListCopy[index]
    if (props.type === 'file') {
      const sample_param = {
        filename: item.filename,
        file_type: item.fileType,
        file_size: item.file_size,
        md5: item.md5,
        sha1: item.sha1,
        sha256: item.sha256
      }
      formData.append('file_sample', JSON.stringify(sample_param))
    } else if (props.type === 'url') {
      formData.append('url', item.value)
    } else if (props.type === 'hash') {
      formData.append('hash', item.value)
    }
    config[item.name] = {
      is_public: is_public.value,
      ...configData,
      os: item.fileType === 'apk' ? 'android' : Linux.includes(item.fileType) ? 'linux' : ''
    }
    if (config[item.name].os === '') delete config[item.name].os
    if (selectTeam.value) {
      config[item.name].team_id = selectTeam.value
    }
    if (item.fileType === 'apk') {
      delete config[item.name].human_popbox
      delete config[item.name].human_scroll
      delete config[item.name].human_curpos
      delete config[item.name].human_autoin
      delete config[item.name].fake_reboot
      delete config[item.name].fast_detect
      delete config[item.name].human_clipbd
      delete config[item.name].pwd_forced
      delete config[item.name].type_in_zip
      delete config[item.name].in_zip
      delete config[item.name].pwd_to_mso
      delete config[item.name].pwd_to_zip
      delete config[item.name].office
      delete config[item.name].emailer
    } else if (Linux.includes(item.fileType)) {
      delete config[item.name].human
      delete config[item.name].human_popbox
      delete config[item.name].human_scroll
      delete config[item.name].human_curpos
      delete config[item.name].human_autoin
      delete config[item.name].fake_reboot
      delete config[item.name].fast_detect
      delete config[item.name].human_clipbd
      delete config[item.name].pwd_forced
      delete config[item.name].type_in_zip
      delete config[item.name].in_zip
      delete config[item.name].pwd_to_mso
      delete config[item.name].pwd_to_zip
      delete config[item.name].office
      delete config[item.name].emailer
    } else {
      !config[item.name].pwd_to_mso && delete config[item.name].pwd_to_mso
      !config[item.name].pwd_to_zip && delete config[item.name].pwd_to_zip
      !config[item.name].cmd && delete config[item.name].cmd
      !config[item.name].function && delete config[item.name].function
      !config[item.name].arguments && delete config[item.name].arguments
      !config[item.name].office && delete config[item.name].office
      !config[item.name].emailer && delete config[item.name].emailer
      !config[item.name].browser && delete config[item.name].browser
      if (props.type === 'url') {
        delete config[item.name].type_in_zip
        delete config[item.name].in_zip
        delete config[item.name].office
        delete config[item.name].emailer
      } else {
        if (!config[item.name].in_zip) {
          delete config[item.name].type_in_zip
          delete config[item.name].md5_in_zip
          delete config[item.name].file_in_zip
          delete config[item.name].in_zip
        }
        if (config[item.name].type_in_zip === 'type_in_zip' && config[item.name].in_zip) {
          config[item.name].type_in_zip = config[item.name].in_zip
          delete config[item.name].md5_in_zip
          delete config[item.name].file_in_zip
          delete config[item.name].in_zip
        }
        if (config[item.name].type_in_zip === 'md5_in_zip' && config[item.name].in_zip) {
          config[item.name].md5_in_zip = config[item.name].in_zip
          delete config[item.name].type_in_zip
          delete config[item.name].file_in_zip
          delete config[item.name].in_zip
        }
        if (config[item.name].type_in_zip === 'file_in_zip' && config[item.name].in_zip) {
          config[item.name].file_in_zip = config[item.name].in_zip
          delete config[item.name].type_in_zip
          delete config[item.name].md5_in_zip
          delete config[item.name].in_zip
        }
      }
    }
  })
  formData.append('submit_type', 'web_submit')
  formData.append('config_map', JSON.stringify(config))
  // TODO:这里整理一下
  formData.append(
    'category',
    props.type === 'hash'
      ? 'hash'
      : props.type === 'android' || props.type === 'linux'
        ? 'file'
        : props.type
  )
  // for (const [a, b] of formData.entries()) {
  //   console.log(a, b)
  // }

  const loading = ElLoading.service({
    lock: true,
    text: t('detection.submitting'),
    background: 'rgba(0, 0, 0, 0.7)'
  })
  postTaskSubmitSingle(formData, token)
    .then((res) => {
      res.task_ids = Object.values(res) || []
      if (res.code !== undefined && res.code !== 0) {
        store.commit('app/showMessage', {
          type: 'error',
          message: res.message.join(', '),
          duration: 1000,
          offset: 60
        })
      } else if (res.task_ids) {
        store.commit('app/showMessage', { message: t('detection.submit_success'), duration: 1000, offset: 60 })
        if (res.task_ids.length > 1) {
          router.push({ name: 'taskstatus', query: { tasklist: res.task_ids.join(',') } })
        } else {
          router.push({ name: 'report', params: { taskId: res.task_ids[0] } })
        }
      } else {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000,
          offset: 60
        })
      }
      isSubmit.value = false
      // 如果用了高级权限就得更新一下数据
      if (usePromiseName.value) {
        store.dispatch('user/getScoreInfo')
      }
    })
    .catch((err) => {
      console.log(err)
      if (err.response?.data?.url) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.url_submit_error'),
          duration: 1000,
          offset: 60
        })
      } else if (err.response.status !== 406 && err.response.status !== 429) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('detection.submit_failed'),
          duration: 1000,
          offset: 60
        })
      }
      isSubmit.value = false
    })
    .finally(() => loading.close())
}

function checkLoginHandle() {
  checkLogin().then((res) => {
    if (!res.is_login) {
      captchDialogVisible.value = true
      showErrPage.value = true
    } else {
      submitData()
    }
  })
}

function closeCaptchDialogHandle(token) {
  isSubmit.value = false
  captchDialogVisible.value = false
  if (token) {
    showErrPage.value = false
    submitData(token)
  }
}

function submit(isPublish) {
  if (isSubmit.value) return
  if (!userInfo.value.group) {
    emitter.emit('showLogin')
    return
  }
  isSubmit.value = true
  if (inputError.value) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.err_config_item'),
      duration: 1000,
      showClose: true,
      offset: 60
    })
    isSubmit.value = false
    return
  }
  if (!isChecked.value) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.err_agreement'),
      duration: 1500,
      showClose: false,
      offset: 60
    })
    isSubmit.value = false
    return
  }
  if (sizeCount.value > quotaMsg.fileSize.value) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('detection.totol_size_too_large'),
      duration: 1000,
      offset: 60
    })
    isSubmit.value = false
    return
  }
  if (isPublish === true) {
    checkLoginHandle()
  } else {
    if (is_public.value) {
      isShowPublish.value = true
      isSubmit.value = false
    } else {
      checkLoginHandle()
    }
  }
}

function showUpgrade() {
  if (!userInfo.value.group || userInfo.value.group === 'free') {
    isShowUpgrade.value = true
  }
}

watch(loginState, (data) => {
  // 如果登录了就默认勾选条款
  isChecked.value = data
  is_public.value = !userInfo.value.group || userInfo.value.group === 'free'
  if (loginState.value) {
    // 获得团队信息
    store.dispatch('team/getTeamMembers')
  }
})

const computed_ownDataList = computed(() => cloneDeep(ownDataList.value))

watch(() => computed_ownDataList.value, (data, old) => {
  // todo
  !data.length && toDetection()
  if (!old) {
    data.map(v => {
      initChange(v)
    })
  }
  if (data.length < old?.length) return
  nextTick(() => {
    const container = document.querySelector('.uploadConfig-left__list')
    container && container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
  })
}, { immediate: true, deep: true })

watch(() => userInfo.value, () => { setQuotaMsg() })

watch(platform, (data) => {
  curPlatform.value = data[0]
})

// watch(curPlatform, (v) => {
//   console.log(baseConfig)
// })

onMounted(() => {
  isChecked.value = loginState.value
  is_public.value = !userInfo.value.group || userInfo.value.group === 'free'
  if (loginState.value) {
    // 获得团队信息
    store.dispatch('team/getTeamMembers')
  }
  setQuotaMsg()
  const platforms = ['Windows', 'Linux', 'Android']
  platforms.map((item) => {
    baseConfig[item] = {
      strategy: 2,
      urlObjectConfig: 'browser',
      env_var: [],
      timeout: 120,
      internet: true,
      decrypt_https: gid > 1,
      human: true,
      human_popbox: true,
      human_scroll: true,
      human_curpos: true,
      human_autoin: false,
      fake_reboot: true,
      fast_detect: true,
      human_clipbd: true,
      pwd_forced: true,
      useNetCheckbox: true,
      emailer: 'extract',
      office: 'mso',
      type_in_zip: 'md5_in_zip',
      netRadio: 'tor'
    }
  })
  emitter.on('reloadResult', () => {
    showErrPage.value = false
  })
})

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme=light]{
  .uploadConfig-left__item .items {
    &:hover {
      .uploadConfig-left__item--configIcon, .uploadConfig-left__item--configIconActive {
        filter: brightness(50%);
      }
    }
  }
  .uploadConfig-left--info {
    border-top-color: #E8EAED !important;
  }
}
.uploadConfig {
  color: var(--color-text-2);
  font-size: 14px;
  padding: 16px 20px;

  &-breadcrumb {
    margin-bottom: 20px;

    &__now {
      color: var(--color-text-1);
    }

    &__back {
      color: #00AB7A;
      cursor: pointer;
      margin-right: 15px;
    }
  }

  &-tooltip {
    float: right;
  }

  &-content {
    margin: 0 auto;
    height: calc(100vh - 146px);
    min-height: 370px;
    display: flex;
  }

  &-title {
    font-size: 14px;
    color: var(--color-text-1);
    height: 50px;
    line-height: 50px;
    margin: 0 20px;
    box-shadow: 0 1px 0 0 var(--color-border-2);
    position: relative;
    z-index: 101;
    font-weight: 500;
    &__after {
      float: right;
      font-weight: normal;
    }
    &__platform {
      font-size: 14px;
      color: var( --color-text-3);
      margin-left: 20px;
      cursor: pointer;
      position: relative;
      &.active {
        color: var(--color-text-1);
        &::after {
          content: '';
          position: absolute;
          bottom: -15.5px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #00ab7a;
        }
      }
    }
    &__before {
      float: right;
      color: #00ab7a;
      font-size: 14px;
      cursor: pointer;

      &--img, &--imgActive {
        margin-bottom: -2px;
        margin-right: 8px;
      }

      &--imgActive {
        display: none;
      }

      &:hover {
        .uploadConfig-title__before--img {
          display: none;
        }

        .uploadConfig-title__before--imgActive {
          display: inline;
        }
      }

      &--line {
        margin-left: 20px;
        // color: #858898;
        color: var(--color-text-3);
      }
    }

    &__openIntegral {
      padding: 7px 16px;
      background-image: linear-gradient(155deg, #EAC998 0%, #D5A763 100%);
      border-radius: 0 4px 0 16px;
      float: right;
      font-size: 14px;
      line-height: 12px;
      color: #331503;
      text-align: center;
      transform: translateX(20px);
    }
  }

  .fileConfig_line {
    border-top: 1px solid var(--color-border-2);
    margin: 0 20px;
  }

  &-left {
    // width: 60%;
    flex: 1;
    height: calc(100%);
    border-radius: 4px;
    float: left;

    // TODO: 单独的android的处理,以后删掉
    &__android {
      width: 100%;
    }
    .leftTop {
      height: calc(100% - 210px);
      background-color: var(--detect-config-bg);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      .uploadConfig-left--info {
        width: calc(100% - 40px);
        margin: 0 20px;
        border-top: 1px dashed #676e85;;
        background: var(--detect-config-bg);
        text-align: right;
        font-size: 14px;
        color: var(--color-text-2);
        box-shadow: 0 -15px 14px -11px #00000022;
        span {
          margin-right: 20px;
        }
        .addFile {
          box-sizing: border-box;
          height: 32px;
          line-height: 32px;
          border-radius: 2px 0px 0px 2px;
          border: 1px dashed #00ab7a;
          padding: 0 16px;
          font-size: 14px;
          color: #00ab7a;
          text-align: center;
          margin: 24px auto 20px;
          cursor:pointer;
          i {
            margin-right: 6px;
            position: relative;
            top: 2px
          }
        }
      }
      .uploadConfig-left--info-url{
        p {
          height: 18px;
          line-height: 18px;
          margin: 14px 0;
        }
      }
      .sandUrlUpload-content {
        border: 1px dashed #00ab7a;
        border-radius: 2px;
        position: relative;
        margin: 2px 20px 22px;
        .icon-right {
          cursor: pointer;
          position: absolute;
          bottom: 4px;
          right: 10px;
          font-size: 24px;
        }
      }
      .sandUrlUpload-content--focus {
        border: 1px solid #00ab7a;
      }
      .sandUrlUpload-input {
        & :deep(.el-textarea__inner) {
          width: 100%;
          min-height: 40px;
          border: none !important;
          background: transparent !important;
          // color: var( --color-unkown-text);
          color: var(--color-text-2);
          padding: 5px 36px 5px 15px;
          box-shadow: none;
          &::-webkit-scrollbar{width:0px;}
          &::placeholder {
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color:#00ab7a;
          }
        }
      }
    }

    &__list {
      flex: 1;
      margin: 16px 20px 0;
      overflow: auto;
      transition: all .5s;
      @include scroll_bar();
      .dragPlaceholder {
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        border-radius: 2px;
        height: 40px;
        border: 1px dashed #00ab7a;
        padding: 0 16px;
        font-size: 14px;
        color: var(--color-text-1);
        display: none;
        i {
          margin-right: 10px;
          position: relative;
          top: 2px
        }
      }
      &.list-drag-active {
        .dragPlaceholder {
          display: block;
        }
      }
    }

    &__item {
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      margin-bottom: 6px;
      // bg_color(color-detection-file-list-bg);
      background-color: var(--detect-config-item-default);
      border-radius: 2px;
      height: 40px;
      border: 1px solid var(--color-border-2);
      padding: 0 16px;
      cursor: pointer;
      position: relative;
      .pro {
        position: absolute;
        left: 0;
        height: 38px;
        box-sizing: border-box;
        background-color: var(--detect-config-item-success);
        border-right: 1px solid var(--color-border-2);
        // background-image: repeating-linear-gradient(310deg,
        // hsla(0,0%,100%,.1),
        // hsla(0,0%,100%,.1) 15px,
        // transparent 0,transparent 30px);
        // background-size: 100%;
        // background-repeat: no-repeat
        transition: width 1s;
        // &:hover {
        //   width calc(100% - 2px)
        // }
      }
      .items {
        width: 100%;
        height: 40px;
        left: -16px;
        box-sizing: border-box;
        padding: 0 16px 0 32px;
        position: absolute;
        .item-delete {
          float: right;
          font-size: 20px;
          line-height: 40px;
          color: var( --color-text-3);
          margin-left: 24px;
          position: relative;
          top: 10px;
        }
        .uploadConfig-left__item--status {
          min-width: 100px;
          float: right;
          line-height: 40px;
          font-size: 14px;
          text-align: left;
          margin-left: 16px;
        }
        .uploadConfig-left__item--fileType {
          width: 78px;
          text-align: left;
          float: right;
          line-height: 40px;
          font-size: 14px;
          // margin-right: 20px;
        }
      }
      &.wrong {
        border: 1px solid transparent;
        .pro {
          width: 100%;
          border: none;
          background-color: var(--color-red-bg);
        }
        .uploadConfig-left__item--name,.uploadConfig-left__item--status {
          color: #EB575F;
        }
        .uploadConfig-left__item--icon {
          content: url('../../../assets/img/detection/failed_icon.svg');
        }
        &:hover {
          border: 1px solid #EB575F;
        }
      }
      &.success {
        border: 1px solid var(--detect-config-item-success);
        .pro {
          width: 100%;
          border: none;
        }
        .uploadConfig-left__item--status {
          color: #00ab7a;
        }
        // .uploadConfig-left__item--icon {
        //   content url('../../assets/images/detection/success_icon.svg')
        // }
      }
      &Active {
        // height: 28px;
        // line-height: 28px;
        border: 1px solid #00AB7A !important;
        padding: 0 15px;
        // bd_color(color-main-highlight);

        .uploadConfig-left__item--icon {
          margin-bottom: 11px;
        }

        .uploadConfig-left__item--configIcon, .uploadConfig-left__item--configIconActive {
          // margin-top: 7px;
        }
      }
      &:hover {
        border: 1px solid #00AB7A;
        // bg_color(color-detection-file-list-bg-active, false);
        color: var(--color-text-1);

        & .uploadConfig-left__item--icon, & .uploadConfig-left__item--configIcon, & .uploadConfig-left__item--configIconActive {
          filter: brightness(140%);
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &--icon {
        height: 18px;
        margin-bottom: 11px;
      }

      &--name {
        min-width: 272px;
        // max-width: 400px;
        max-width: 305px;
        margin-left: 8px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--size {
        width: 60px;
        float: right;
        margin:0 16px 30px;
      }

      &--configIcon {
        float: right;
        display: inline-block;
        width: 20px;
        height: 20px;
        transform:translateY(10px);
      }

      &--configIconActive {
        float: right;
        display: inline-block;
        width: 20px;
        height: 20px;
        transform:translateY(10px);
      }
    }
    .leftBottom {
      // margin: 16px 20px 0;
      height: 194px;
      margin-top: 16px;
      border-radius: 2px;
      overflow: auto;
      background-color: var(--detect-config-bg);
      box-shadow: 0, 0, 4px, 0, #0D112B;
      .private {
        display: flex;
      }
      .uploadConfig-left__radio {
        & :deep(.el-switch__label) {
          // color: var(--color-text-2);
        }
      }
    }
    &__radio {
      width: 300px;
      margin: 16px 0 12px 26px;
      line-height: 32px;
      display:flex;
      align-items: center;
      &--label {
        margin-right: 10px;
        color: var(--color-text-1);
      }

      & :deep(.el-radio__label) {
        color: var(--color-text-2);
      }

      & :deep(.el-switch) {
        // flex-direction: row-reverse;
        .el-switch__label--left {
          margin:0 10px 0 0;
          font-size: 14px;
          span {
            font-size: 14px;
          }
        }
        .el-switch__label--right {
          font-size: 14px;
          margin:0 0 0 10px;
          span {
            font-size: 14px;
          }
        }
      }

      & :deep(.el-radio__input) {
        & .el-radio__inner {
          border: 1px solid var(--color-priamry);
          background: transparent;
          height: 16px;
          width: 16px;
        }

        &.is-checked .el-radio__inner {
          background-color: var(--color-priamry);
          border: 1px solid var(--color-priamry);
          &::after {
            height: 7px;
            width: 7px;
            background-color: #a7c6fa;
          }
        }
      }
    }

    &__select {
      margin: 16px 0 12px 0;

      &--label {
        margin-right: 10px;
        color: var(--color-text-1);
      }

      & :deep(.el-select) {
        @include select();
        width: 180px;
        display: inline-block;
        & .el-select-dropdown__item {
          padding-right: 30px;
          background: transparent;
        }

        & .el-tag {
          background: var(--detect-config-team);
          border-color: var(--detect-config-team);
        }

        & .el-select__tags-text {
          display: inline-block;
          color: var(--color-text-1);
          max-width: 55px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & .el-tag__close {
          // margin-top: -17px;
        }

        .el-input__inner {
          background-color: transparent;
        }

        & .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
          right: 12px;
        }
      }
    }

    &__checkbox {
      display: flex;
      margin: 0 26px;

      & :deep(.el-checkbox__input) {
        margin-top: 2px;

        .el-checkbox__inner {
          background: transparent;
          border: 1px solid var(--color-priamry);
          &::after {
            border-width: 2px;
          }
        }

        &.is-checked .el-checkbox__inner {
          background-color: var(--color-priamry);
          border: 1px solid var(--color-priamry);
        }
      }

      & :deep(.el-checkbox__label) {
        display: inline-block;
        white-space: pre-wrap;
        padding-left: 10px;
        color: var(--color-text-2);
        &:focus {
          outline: none;
        }

        & a {
          color: var(--color-priamry);
          text-decoration: none;

          &:focus {
            outline: none;
          }
        }
      }
    }
    &__warning {
      font-size: 14px;
      color: var(--color-text-2);
      margin-left: 26px;
      visibility: hidden;
      i {
        margin-right: 10px;
      }
    }
  }

  &-right {
    // width 544px
    width: calc(40% - 16px);
    max-width: 620px;
    margin-left: 16px;
    height: 100%;
    background-color: var(--detect-config-bg);
    border-radius: 2px;
    position: relative;
    &__file {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-top: none;
      background-color: transparent;
      // box-shadow: 0 1px 0 0 var(--color-border-2);
      position: relative;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.active {
        background-color: var( --color-bg-4);
      }
      &--icon {
        margin-bottom: 17px;
        margin-right: 8px;
      }

      & > span {
        display: inline-block;
        width: 350px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--color-text-1);
      }

      &--checkbox {
        float: right;
        display: flex !important;
        align-items: center;
      }

      & :deep(.el-checkbox) {
        display: inline-block;

        & .el-checkbox__input {
          .el-checkbox__inner {
            background: transparent;
            // border: 1px solid var(--color-priamry);
            &::after {
              border-width: 2px;
            }
          }

          &.is-checked .el-checkbox__inner {
            background-color: var(--color-priamry);
            border: 1px solid var(--color-priamry);
          }
        }

        & .el-checkbox__label {
          // ft_color(color-detection-font);
          color: var(--color-text-1);

          &:focus {
            outline: none;
          }
        }
      }
    }

    &__config {
      overflow: hidden;
      padding: 0 20px;
      height: calc(100% - 51px);
      overflow-y: auto;
      @include scroll_bar();

      &--disable {
        // overflow-y hidden
        &::after {
          content: ' ';
          position: absolute;
          left: 0;
          top: 100px;
          height: calc(100% - 100px);
          width: 100%;
          background-color: var(--detect-config-mask);
          z-index: 10000;
        }
      }
    }
  }
  .btns {
    display: flex;
    justify-content: center;
    margin-top: 17px;
    button {
      width: 108px;
      height: 32px;
      border-radius: 2px;
      text-align: center;
      line-height: 8px;
      font-size: 14px;
    }
    .uploadConfig-btn {
      color: #fff;
      background: #009e71;
      border: none;
    }
    .uploadConfig-clear-btn {
      border: 1px solid var( --color-border-1);
      color: var( --color-text-2);
      background: var(--detect-config-item-default);
      margin-left: 8px;
    }
  }
  &-progress {
    width: 196px;
    height: 32px;
    background: #34427D;
    border-radius: 4px;
    margin: 30px auto 0;
    overflow: hidden;
    position: relative;

    &__text {
      width: 196px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: var(--color-text-1);
      position: absolute;
      left: 0;
      top: 0;
      white-space: nowrap;
    }

    &__inner {
      width: 0px;
      height: 32px;
      background-image: linear-gradient(184deg, #3491F9 0%, #3565E6 100%);
      box-shadow: 2px 2px 8px 0 rgba(60, 77, 124, 0.3);
    }
  }
}

// @media screen and (min-width: 1500px) {
//   .uploadConfig-left {
//     width: calc(100% - 630px);
//   }
// }
</style>
